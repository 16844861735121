var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"px-2",attrs:{"md":"4"}},[_c('Objective',{attrs:{"title":"Practical Training","targets":[
          {
            highlight: 'Learn good practices',
            rest: 'in basic attack prevention to raise awareness with all audiences',
          },
          {
            highlight: 'Train your team',
            rest: 'with cyber skills or academic courses',
          },
          {
            highlight: 'Create operational exercises',
            rest: 'close to daily environments',
          },
          {
            highlight: 'Manage your company and your systems',
            rest: 'in a crisis scenario to test your process',
          } ]},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/registry/consultancy.png"),"height":"100"}})]},proxy:true}])})],1),_c('b-col',{staticClass:"px-2",attrs:{"md":"4"}},[_c('Objective',{attrs:{"title":"Operational Qualification","targets":[
          {
            highlight: 'Evaluate the impact of integrating new equipment,',
            rest: 'new rules or applying a security patch on a system',
          },
          {
            highlight: 'Analyse the behaviour of cyber-attacks',
            rest: 'on our infrastructure without taking any risks',
          },
          {
            highlight: 'Ensure maximum security',
            rest: 'to avoid production shutdown during a cyber-attack',
          },
          {
            highlight: 'Reassure your customers',
            rest: 'about your cyber protection',
          },
          {
            highlight: 'Validate certifications',
            rest: 'and ensure secure connections',
          } ]},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/registry/services.png"),"height":"100"}})]},proxy:true}])})],1),_c('b-col',{staticClass:"px-2",attrs:{"md":"4"}},[_c('Objective',{attrs:{"title":"Production Testing","targets":[
          {
            highlight: 'Launch a pre-production evaluation',
            rest: 'of the impact of adding the selected product with the need to recreate a hyper realistic environment',
          },
          {
            highlight: 'Evaluate your product',
            rest: 'before final selection',
          },
          {
            highlight: 'Recruit new people',
            rest: 'with exams',
          },
          {
            highlight: 'Explore new security equipment',
            rest: 'and procedures in a realistic environment',
          } ]},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/registry/production.png"),"height":"100"}})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }