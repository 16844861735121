<template>
  <b-container v-bind:id="resource">
    <div class="items-options">
      <div
        v-if="!isBusy & pagination.total > 0"
        class="search-content"
        style="width: 100%">
        <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
        <input
          id="loadResource"
          ref="loadResource"
          type="search"
          style="width: 100%; margin-top: 0; padding: 2px; height:20px;"
          v-model="search"
          @keyup="filter"
          @mouseup="mouseupSearchEvent"
          class="form-control my-2 input-search"
          :placeholder="'Search for ' + resource + '...'"
        />
        <p v-if="!isBusy & pagination.total > 0" class="items">{{ pagination.total }} items</p>
      </div>
    </div>

<!--    <Pagination-->
<!--      :page="page"-->
<!--      :limit="limit"-->
<!--      :total="pagination.total"-->
<!--      :totalPages="this.totalPages()"-->
<!--      :limitOptions="limitsOptionsPagination"-->
<!--    />-->

    <b-row
      v-if="
        resource === 'files' &&
        $store.getters['token/hasPermission']('FILE', 'CREATE')
      "
      class="my-2"
    >
      <FileListForm
        :forcedBundleIdentifier="bundleIdentifier"
        addEvent="add-file-show"
      />
      <b-button
        variant="outline-dark"
        block
        @click="$eventBus.$emit('add-file-show')"
      >
        <font-awesome-icon class="mr-2 mt-1" :icon="['fas', 'plus']" />
        Add file
      </b-button>
    </b-row>

    <b-row v-for="res in resources" :key="res.identifier">
      <ActionTile v-if="resource === 'actions'" :resource="res" />
      <TopologyTile v-else-if="resource === 'topologies'" :resource="res" />
      <ScenarioTile v-else-if="resource === 'scenarios'" :resource="res" />
      <FileTile v-else-if="resource === 'files'" :resource="res" />
    </b-row>

    <b-row
      v-if="!pagination.total & !isBusy"
      class="d-flex justify-content-center text-xl my-5"
    >
      No content.
    </b-row>

    <div class="pagination-options" v-if="pagination.total_pages > 1">
      <div>
        <b-select
          :options="limitOptions"
          v-model="limit"
          @change="loadResources"
          class="my-2"
        />
      </div>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-model="page"
          :total-rows="pagination.total"
          @input="loadResources"
          :per-page="limit"
          first-number
          last-number
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import ActionTile from "@/components/hub/actions/ActionTile";
import TopologyTile from "@/components/hub/topologies/TopologyTile";
import ScenarioTile from "@/components/hub/scenarios/ScenarioTile";
import FileTile from "@/components/hub/files/FileTile";
import FileListForm from "@/components/files/FileListForm";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "ResourceTiles",
  components: {
    ActionTile,
    TopologyTile,
    ScenarioTile,
    FileTile,
    FileListForm,
    Pagination,
  },
  props: {
    resource: String,
    bundleIdentifier: String,
    reload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      search: null,
      isBusy: true,
      loads: {},
      resources: null,
      pagination: {
        total: 0,
      },
      page: 1,
      limit: 10,
      limitsOptionsPagination: [10,15,20,50],
      limitOptions: [
        { value: 10, text: "10 elements per page" },
        { value: 15, text: "15 elements per page" },
        { value: 25, text: "25 elements per page" },
        { value: 50, text: "50 elements per page" },
      ],
      orderBy: "created_date",
      desc: false,
      preview: null,
    };
  },
  mounted()  {
    if(this.reload) {
      this.loadResources()
      this.$emit("loaded")
    } else {
      this.resources = this.$store.getters[this.resource + "/getAll"]()
      this.pagination = this.$store.getters[this.resource + "/getPagination"]()
    }
  },
  computed: {},
  methods: {
    totalPages() {
      let pages = Math.trunc(this.pagination.total / this.limit)
      if ((this.pagination.total / this.limit) - pages !== 0)
        pages++
      return pages
    },
    loadResources() {
      this.isBusy = true;
      return this.$store
        .dispatch(this.resource + "/loadAll", {
          filters: { bundleIdentifier: this.bundleIdentifier },
          flatten: true,
          limit: this.limit,
          page: this.page,
          load: "normal",
          search: this.search,
        })
        .then(() => {
          this.resources = this.$store.getters[this.resource + "/getAll"]();
          this.pagination =
            this.$store.getters[this.resource + "/getPagination"]();
          this.isBusy = false;
        })
        .catch((response) => {
          this.$eventBus.$emit("http-error", response);
        });
    },
    filter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadResources();
      }, 600);
    },
    mouseupSearchEvent(_) {
      let oldvalue = this.$refs.loadResource.value;

      if (oldvalue === "") {
        return;
      }

      //// When this event is fired after clicking on the clear button
      //// the value is not cleared yet. We have to wait for it.
      setTimeout(() => {
        var newValue = this.search;
        if (newValue === "") {
          this.loadResources();
        }
      }, 1);
    },
  },
};
</script>

<style scoped>
.items-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
}

.items {
  display: block;
  text-align: center;
}

.search-content {
  width: 100% !important;
}

.search-content .input-search {
  padding: 20px 20px 20px 35px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
}

.search-content svg {
  left: 10px;
  top: 15px;
}

.search-content .input-search:focus{
  padding-left: 20px !important;
}

.pagination-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>
