import 'jquery-ui-dist/jquery-ui'

import Config from './Config'

import Vue from 'vue'
import Vuex from 'vuex'

import BootstrapVue from 'bootstrap-vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import LoadScript from 'vue-plugin-load-script'

import App from '@/AppRegistry.vue'
import Home from '@/components/hub/Home.vue'
import Forbidden from '@/components/Forbidden.vue'
import LoginView from '@/components/hub/LoginView.vue'
import ResetPasswordForm from '@/components/auth/ResetPasswordForm.vue'
import BundleList from '@/components/bundles/BundleList.vue'
import GroupList from '@/components/groups/GroupList.vue'
//TODO: a enlever après
import SettingsView from '@/components/profile/SettingsView.vue'
import SettingsUser from '@/components/profile/SettingsUser.vue'
import AccessTokenList from '@/components/profile/AccessTokenList.vue'
import LogoutView from '@/components/profile/LogoutView.vue'
import WorkflowView from '@/components/workflows/WorkflowView.vue'
import UploadList from '@/components/upload/UploadList.vue'
import AcceptEULA from '@/components/auth/AcceptEULA.vue'
import ConfigurationView from '@/components/configuration/ConfigurationView.vue'
import LegalView from '@/components/auth/LegalView.vue'
import BundleGrid from '@/components/hub/Explore.vue'
import GroupBundleGrid from "@/components/hub/GroupBundleGrid";
import editGroupBundle from "@/components/hub/groups/EditGroup.vue";
import BundleContent from '@/components/hub/BundleContent.vue'
import QuestionView from '@/components/hub/questions/QuestionView.vue'
import QuestionForm from "@/components/hub/questions/QuestionForm";
import TopologyContent from "@/components/hub/topologies/TopologyContent";
import NotificationList from "@/components/notifications/NotificationList";
import EditGroup from "@/components/hub/groups/EditGroup.vue";
import NavView from '@/components/hub/NavView.vue'
import store from '@/store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'

import ModalPlugin from './ModalPlugin.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import ScenarioContent from "./components/hub/scenarios/ScenarioContent";
import ActionContent from "./components/hub/actions/ActionContent";
import FileContent from "./components/hub/files/FileContent"

import '@/assets/css/main.css'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true // from vue-clipboard2 documentation: fix for clipboard to work with bootstrap modals
Vue.use(VueClipboard);

import ResourceList from '@/components/ResourceList.vue';
Vue.component('ResourceList', ResourceList);

import ResourceTiles from "@/components/hub/ResourceTiles.vue";
Vue.component('ResourceTiles', ResourceTiles);

import VueMarkdown from 'vue-markdown-v2'
Vue.component('vue-markdown', VueMarkdown)

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';
Vue.component('editor', Editor)

Vue.use(VueResource)
Vue.use(LoadScript)
Vue.use(Vuex)

// Import and install filters
import {
  print_tag,
  print_date,
  print_permission,
  print_human_readable_size,
  capitalize
}

  from './filters';

Vue.filter("print_tag", print_tag);
Vue.filter("print_date", print_date);
Vue.filter("print_human_readable_size", print_human_readable_size);
Vue.filter("capitalize", capitalize);
Vue.filter("print_action", print_permission);

Vue.use(ModalPlugin);

const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus

library.add(fab, fas, far)
Vue.component(FontAwesomeIcon.name, FontAwesomeIcon)

Vue.use(VueRouter)
Vue.use(BootstrapVue)

Vue.use("/images", "@/assets/images");

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'home',
      path: '/',
      component: Home
    },
    {
      name: 'legal',
      path: '/legal',
      component: LegalView
    },
    {
      name: 'login',
      path: '/login',
      component: LoginView
    },
    {
      name: 'forbidden',
      path: '/forbidden',
      component: Forbidden,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'bundleGrid',
      path: '/explore',
      props: true,
      component: BundleGrid
    },
    {
      name: 'editGroupBundle',
      props: true,
      path: '/groups_old/:identifier/edit_old',
      component: editGroupBundle,
      meta: {
        requireLogin: true
      }
    },
    {
      path: '/groups/:identifier/edit',
      props: true,
      component: NavView,
      children: [
        {
          name: 'editGroup',
          path: '',
          props: true,
          component: EditGroup,
          meta: {
            requireLogin: true
          }
        }
      ]
    },
    {
      name: 'reset',
      path: '/reset',
      component: ResetPasswordForm,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'bundles',
      path: '/bundles/:identifier?/:resource?',
      component: BundleList,
      meta: {
        requireLogin: true
      }
    },
    {
      path: '/groups/:identifier/explore',
      props: true,
      component: NavView,
      children: [
        {
          name: 'groupBundleGrid',
          path: '',
          props: true,
          component: GroupBundleGrid,
          meta: {
            requireLogin: true
          }
        }
      ]
    },
    {
      path: '/explore/:identifier',
      props: true,
      component: NavView,
      children: [
        {
          name: 'bundlePage',
          path: '',
          props: true,
          component: BundleContent,
          meta: {
            requireLogin: true
          }
        },
        {
          name: 'questionPage',
          path: '/explore/:bundleIdentifier/questions/:identifier(\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b)',
          props: true,
          component: QuestionView,
          meta: {
            requireLogin: true
          }
        },
        {
          name: 'questionForm',
          path: '/explore/:bundleIdentifier/questions/new',
          props: true,
          component: QuestionForm,
          meta: {
            requireLogin: true
          }
        },
        {
          name: 'topologyPage',
          path: '/explore/:bundleIdentifier/topologies/:identifier',
          props: true,
          component: TopologyContent,
          meta: {
            requireLogin: true
          }
        },
        {
          name: 'scenarioPage',
          path: '/explore/:bundleIdentifier/scenarios/:identifier',
          props: true,
          component: ScenarioContent,
          meta: {
            requireLogin: true
          }
        },
        {
          name: 'actionPage',
          path: '/explore/:bundleIdentifier/actions/:identifier',
          props: true,
          component: ActionContent,
          meta: {
            requireLogin: true
          }
        },
        {
          name: 'filePage',
          path: '/explore/:bundleIdentifier/files/:identifier',
          props: true,
          component: FileContent,
          meta: {
            requireLogin: true
          }
        },
      ],
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'groups',
      path: '/groups/:identifier?/:resource?',
      component: GroupList,
      props: true,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'logout',
      path: '/logout',
      component: LogoutView,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'profile_',
      path: '/profile_',
      component: SettingsView,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'profile',
      path: '/profile',
      component: SettingsUser,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'access_token',
      path: '/access_token',
      component: AccessTokenList,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'workflow',
      path: '/workflow',
      component: WorkflowView,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'upload',
      path: '/upload',
      component: UploadList,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'config',
      path: '/config',
      component: ConfigurationView,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'eula',
      path: '/eula',
      component: AcceptEULA,
      meta: {
        requireLogin: true
      }
    },
    {
      name: 'notifications',
      path: '/notifications',
      component: NotificationList,
      meta: {
        requireLogin: true
      }
    },
  ]
})

Config.base = '/'

Vue.http.interceptors.push(function (request, next) {
  request.credentials = true
  let tokenInfos = store.getters['token/getInfos']()
  if (tokenInfos != null) {
    let expires_in = store.getters['token/expires_in']()
    // time in millisecond
    //console.log(expires_in)
    if (expires_in < 300000 && expires_in > 1000 && request.url !== Config.API_TOKEN_URL + '/renew') {
      //console.log("Token is about to expire.")
      store.dispatch('token/renew')
    }
  }
  request.headers.set('Accept', 'application/json')

})

store.dispatch('plugins/runPublic', {
  label: 'registry-webui',
  store: store, app: Vue
}).then((plugins) => {
  for (let plugin of plugins) {
    if (plugin && 'install' in plugin) {
      Vue.use(plugin, {router: router, store: store, config: Config})
    }
  }
}).finally(() => {
  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    base: Config.base
  })
})
