import moment from 'moment'
import {Tag, refactor_number_with_unit, round_decimal} from './common'

function capitalize(value = null, key = null, item = null) {
  if (!value) return ""
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

function print_date(value = null, key = null, item = null) {
  if (!value) return "Never"
  return moment(String(value)).calendar(null, {sameElse: 'DD/MM/YYYY LT'})
}

function print_tag(value = null, key = null, item = null, print_owner = true, print_resource = false) {
  if (!item) return "None"
  return new Tag(item.alias, item.qualifier, item.resource_type, item.group).print(print_resource, print_owner)
}

function print_permission(specs) {
  return function (value = null, key = null, item = null) {
    let permKey = null
    if (item["rule_resource_type"] != null) {
      permKey = item["rule_resource_type"]
    } else if (item["scope_type"] != null) {
      permKey = item["scope_type"]
    } else {
      permKey= "*"
    }

    let verbs = []
    if (specs.permissions[permKey]) {
      for (let permission of specs.permissions[permKey]) {
        if (permission.value === value) {
          return permission.name
        }
        if ((permission.value & value) === permission.value) {
          verbs.push(permission.name)
        }
      }
    }
    return verbs.join(', ')
  }
}

function print_boolean(value = null, key = null, item = null) {
  if (typeof value != "boolean") return "None"
  return value?"Yes":"No"
}

function print_human_readable_size(value = null, key = null, item = null, message = "Invalid") {
  if (!Number.isInteger(value) || value < 0) return message
  if (value === 0) {
    return "Empty"
  }
  let result = refactor_number_with_unit(value)
  return round_decimal(result.number, 2) + " " + result.unit
}

function print_scope(value = null, key = null, item = null) {
  if (!item) return "None"
  if (item.scope_type == null) {
    return "*"
  } else {
    if (item.scope.includes("/")) {
      return capitalize(item.scope)
    } else {
      return capitalize(item.scope_type) + "/" + item.scope
    }
  }
}

function print_resource(value = null, key = null, item = null) {
  if (!item) return "None"
  if (item.rule_resource_type == null) {
    return "*"
  } else {
    return capitalize(item.rule_resource_type)
  }
}

function print_hardware_type(value = null, key = null, item = null) {
  switch (value) {
    case "VM_T":
      return "Virtual machine"
    case "CO_T":
      return "Container"
    case "EM_T":
      return "External"
    default:
      return "Invalid"
  }
}

function print_duration(value = null, key = null, item = null) {
  let result = ""

  if (value.years())
    result = result + value.years() + ' years(s) '

  if (value.months())
    result = result + value.months() + ' months(s) '

  if (value.days())
    result = result + value.days() + ' day(s) '

  if (value.hours())
    result = result + value.hours() + ' hour(s) '

  if (value.minutes())
    result = result + value.minutes() + ' minutes(s) '

  return result
}

export {
  print_tag,
  print_date,
  print_permission,
  print_human_readable_size,
  print_scope,
  print_resource,
  print_hardware_type,
  print_duration,
  capitalize,
  print_boolean,
}

