import Vue from 'vue'
import Config from '../../Config'

const state = {}

const getters = {}

const actions = {
  // IAM
  user({dispatch, commit}, {search, filters = {}}, params = {}) {
    return dispatch('_search', {url: Config.API_USERS_URL, search, params})
  },
  group({dispatch, commit}, {search, filters = {}, params = {}}) {
    return dispatch('_search', {url: Config.API_GROUPS_URL, search})
  },
  // Workzone
  workzone({dispatch, commit}, {search, filters = {}, params = {}}) {
    return dispatch('_search', {url: Config.API_WORKZONES_URL, search})
  },

  category({dispatch, commit}, {search, filters = {}}) {
    var url
    if (filters.bundleIdentifier !== undefined)
      url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/categories"
    else
      url = Config.API_CATEGORIES_URL
    return dispatch('_search', {url, search})
  },

  host({dispatch, commit}, {search, filters = {}, params = {}}) {
    return dispatch('_search', {url: Config.API_HOSTS_URL, search})
  },
  network({dispatch, commit}, {search, filters = {}, params = {}}) {
    return dispatch('_search', {url: Config.API_NETWORKS_URL, search})
  },
  // content
  file({dispatch, commit}, {search, filters = {}, params = {}}) {
    var url
    if (filters.bundleIdentifier !== undefined)
      url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/files"
    else
      url = Config.API_FILES_URL
    return dispatch('_search', {url, search, params: {...params, "load": "meta"}})
  },

  action({dispatch, commit}, {search, filters = {}, params = {}}) {
    var url
    if (filters.bundleIdentifier !== undefined)
      url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/actions"
    else
      url = Config.API_ACTIONS_URL
    return dispatch('_search', {url, search, params: {...params, "load": "meta"}})
  },

  scenario({dispatch, commit}, {search, filters = {}, params = {}}) {
    var url
    if (filters.bundleIdentifier !== undefined)
      url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/scenarios"
    else
      url = Config.API_SCENARIOS_URL
    return dispatch('_search', {url, search, params: {...params, "load": "meta"}})
  },

  topology({dispatch, commit}, {search, filters = {}, params = {}}) {
    var url
    if (filters.bundleIdentifier !== undefined)
      url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/topologies"
    else
      url = Config.API_TOPOLOGIES_URL
    return dispatch('_search', {url, search, params: {...params, "load": "meta"}})
  },

  bundle({dispatch, commit}, {search, filters = {}, params = {}}) {
    return dispatch('_search', {url: Config.API_BUNDLES_URL, search, params: {...params, "load": "meta"}})
  },

  provider({dispatch, commit}, {search, filters = {}, params = {}}) {
    return dispatch('_search', {url: Config.API_PROVIDERS_URL, search, params: {...params, "load": "meta"}})
  },

  // common
  _search({dispatch, commit}, {url, search, params = {}}) {
    if (search !== "")
      params["q"] = search
    return Vue.http.get(url, {params}).then((response) => {
      let resources = response.data
      if (resources.data !== undefined)
        return resources.data
      return resources
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






