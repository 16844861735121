<template>
  <b-container>
    <b-row>
      <b-col
        md="2"
        v-for="(partner, index) in partners"
        :key="index"
        class="d-flex justify-content-center m-5"
        style="height: 65px"
      >
        <img
          :id="`partner-${index}`"
          :src="getImageUrl(partner.src)"
          :alt="partner.alt"
        />
        <b-tooltip :target="`partner-${index}`">{{ partner.alt }}</b-tooltip>
      </b-col>
<!--      <b-col md="2" class="d-flex justify-content-center m-5" style="height: 65px">-->
<!--        <div class="text-green text-6xl align-content-center" style="line-height: 65px">•••</div>-->
<!--      </b-col>-->
<!--      <b-col class="text-green text-9xl d-flex justify-content-center">-->
<!--      </b-col>-->
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Partners",
  computed: {
    partners() {
      return [
        {
          src: "ministere-armees.png",
          alt: "Ministère des armées",
        },
        {
          src: "stormshield.png",
          alt: "Stormshield",
        },
        {
          src: "seela.png",
          alt: "Seela",
        },
        {
          src: "irsn.png",
          alt: "IRSN",
        },
        {
          src: "cea.png",
          alt: "CEA Paris-Saclay",
        },
        {
          src: "cnam.png",
          alt: "CNAM",
        },
        {
          src: "ensibs.png",
          alt: "ENSIBS",
        },
        {
          src: "esiea.png",
          alt: "ESIEA",
        },
        {
          src: "intechinfo.png",
          alt: "INTECH",
        },
        {
          src: "isen.png",
          alt: "ISEN",
        },
        {
          src: "iut-chalons.png",
          alt: "IUT Châlons-en-Champagne",
        },
        {
          src: "imt-atlantique.png",
          alt: "IMT Atlantique",
        },
        {
          src: "irt-systemx.png",
          alt: "IRT SystemX",
        },
        {
          src: "esna.png",
          alt: "ESNA Bretagne",
        },
        {
          src: "polesupdelasalle.png",
          alt: "Pôle Sup de La Salle",
        },
        {
          src: "esaip.png",
          alt: "ESAIP",
        },
        {
          src: "afpi.png",
          alt: "AFPI-UIMM",
        },
        {
          src: "mtu.png",
          alt: "Munster Technological University",
        },
        {
          src: "dndagency.png",
          alt: "DND Agency",
        },
        {
          src: "csb.png",
          alt: "CSB School",
        },
        {
          src: "aforp.png",
          alt: "AFORP",
        },
        {
          src: "ecam.png",
          alt: "ECAM",
        },
        {
          src: "framatome.png",
          alt: "Framatome",
        },
        {
          src: "isep.png",
          alt: "ISEP",
        }
      ];
    },
  },
  methods: {
    getImageUrl(imageFile) {
      if (!imageFile) {
        return;
      }
      var images = require.context(
        "@/assets/registry/partners",
        false,
        /\.png$/
      );
      return images("./" + imageFile);
    },
  },
};
</script>
