<template>

  <b-modal ref="bookingAddFormModal" id="bookingAddFormModal" size="lg" hide-footer>
    <template v-slot:modal-title>
      {{ title }}
      <span v-if="groupIdentifier">
                of group {{ $store.getters['groups/get'](groupIdentifier).name }}
            </span>
    </template>
    <form id="bookingForm" v-on:submit.prevent v-if="groupIdentifier">
      <b-form-group v-if="edit && booking.approved">
        <b-card class="mb-2" title="Informations">

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Name:</b></b-col>
            <b-col>{{ this.booking.name }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-right"><b>Status:</b></b-col>
            <b-col>
              <b-badge v-if="edit" :variant="booking_variant" style="font-size:1em">
                {{ !booking.approved ? "require approval" : booking.status | capitalize }}
              </b-badge>
            </b-col>
          </b-row>

          <b-row class="mb-2 font-size-sm">
            <b-col sm="3" class="text-sm-right"><b>Identifier:</b></b-col>
            <b-col>{{ booking.identifier }}</b-col>
          </b-row>

          <b-row class="mb-2 font-size-sm">
            <b-col sm="3" class="text-sm-right"><b>Group</b></b-col>
            <b-col>{{ $store.getters['groups/get'](booking.group_identifier).name }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Approved by user :</b></b-col>
            <b-col>{{ booking.specs['approved_by'] }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Approved date :</b></b-col>
            <b-col>{{ booking.specs['approved_at'] | print_date }}</b-col>
          </b-row>
        </b-card>
        <b-card class="mb-2" title="Users">
          <ResourceList
            resource="users"
            :resources="userItems"
            hideSearch hideRefresh>

            <template v-slot:cell(active)="row">
              <span class="badge badge-pill badge-success" v-if="row.item.active">Online</span>
              <span class="badge badge-pill badge-secondary" v-else>Offline</span>
            </template>

          </ResourceList>
        </b-card>
        <b-card class="mb-2" title="Workzones">
          <ResourceList
            resource="workzones"
            :resources="workzonesSelected"
            hideSearch hideRefresh/>
        </b-card>
        <b-card class="mb-2" title="Workflows">
          <WorkflowList
            :fields="workflowFields"
            :resources="booking.workflows"
            :dynamicLoad="false"
            :loadOnToggle="false"/>
        </b-card>
      </b-form-group>
      <b-form-group v-else>
        <div role="tablist">
          <InputText
            prefix="Booking"
            name="Booking name"
            id="booking-name"
            v-model="name"
            :required="true"/>

          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block href="#" class="text-left" v-b-toggle.accordion-workzones variant="light">
                <h4 :class="{colordanger: step1Error}">
                  <font-awesome-icon :icon="['fas', 'folder']"/>
                  Workzones
                </h4>
              </b-button>
            </b-card-header>

            <b-collapse id="accordion-workzones" accordion="options-accordion" role="tabpanel" visible>
              <div v-if="workzones.length === 1" style="margin:15px;">
                Selected workzone: {{ workzones[0].identifier }}
                <b-badge> {{ workzones[0].name }}</b-badge>
              </div>

              <div v-else>
                <div style="margin:15px;">
                  <b-form-radio-group
                    v-model="workzonesSelection"
                    stacked
                    name="workzone-section">
                    <b-form-radio value="automatic">Select workzone(s) automatically</b-form-radio>
                    <b-form-radio value="select">Choose workzones</b-form-radio>
                  </b-form-radio-group>
                </div>

                <b-container fluid v-if="workzonesSelection==='automatic'">
                  <b-row>
                    <b-col cols="auto" class="mr-auto">
                      <label><strong>Number of workzones selected (between 1 and {{ workzones.length }}
                        maximum): </strong></label>
                    </b-col>
                    <b-col cols="auto" class="pr-3">
                      <h4>
                        <b-badge>{{ workzonesAmount }} selected</b-badge>
                      </h4>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="w-100">
                        <span style="float:left"> 1 </span>
                        <span style="float:right"> {{ workzones.length }} </span>
                      </div>
                      <b-form-input @change="updateAvailability()" min="1" :max="workzones.length" type="range"
                                    v-model="workzonesAmount"></b-form-input>
                    </b-col>
                  </b-row>
                </b-container>

                <b-container fluid v-if="workzonesSelection==='select'">
                  <b-row>
                    <b-col cols="6">
                      <TypeAhead
                        ref="workzoneTypeahead"
                        :customResources="workzones"
                        resourceType="workzone"
                        class="mb-2"
                        @hit="addWorkzone($event)"/>
                    </b-col>
                    <b-col cols="3">
                      <b-button
                        variant="success"
                        block
                        size="sm"
                        @click="addAllWorkzones"
                        :disabled="workzonesSelected.length === workzones.length">

                        <font-awesome-icon :icon="['fas', 'plus']"/>
                        Add all

                      </b-button>
                    </b-col>
                    <b-col cols="3">
                      <b-button
                        variant="danger"
                        block size="sm"
                        @click="removeAllWorkzones"
                        :disabled="workzonesSelected.length === 0">

                        <font-awesome-icon :icon="['fas', 'minus']"/>
                        Remove all

                      </b-button>
                    </b-col>
                  </b-row>

                  <ResourceList
                    resource="workzones"
                    :resources="workzonesSelected"
                    deleteEvent="remove-workzone"
                    deleteIcon="times"
                    :filters="{ isDeletable: (entry) => true }"
                    hideSearch hideRefresh/>

                  <b-alert v-if="workzonesSelected.length === 0" show variant="warning">
                    <font-awesome-icon :icon="['fas', 'exclamation']"/>
                    No workzone selected. You must select at least one workzone for this booking.
                  </b-alert>

                </b-container>
              </div>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block href="#"
                        class="text-left"
                        v-b-toggle.accordion-users
                        variant="light">

                <h4 :class="{colordanger: step2Error}">
                  <font-awesome-icon :icon="['fas', 'users']"/>
                  Users
                </h4>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-users" accordion="options-accordion" role="tabpanel">
              <b-card-body>
                <b-row v-if="users.length > 0">
                  <b-col cols="6">
                    <TypeAhead ref="userTypeahead" resourceType="user" :customResources="users" class="mb-2"
                               @hit="addUser($event)"/>
                  </b-col>
                  <b-col cols="3">
                    <b-button variant="success" block size="sm" @click.stop="addAllUsers"
                              :disabled="userItems.length === this.users.length">
                      <font-awesome-icon :icon="['fas', 'plus']"/>
                      Add all
                    </b-button>
                  </b-col>
                  <b-col cols="3">
                    <b-button variant="danger" block size="sm" @click.stop="removeAllUsers"
                              :disabled="userItems.length === 0">
                      <font-awesome-icon :icon="['fas', 'minus']"/>
                      Remove all
                    </b-button>
                  </b-col>
                </b-row>

                <ResourceList
                  resource="users"
                  :resources="userItems"
                  deleteEvent="remove-user"
                  :filters="{ isDeletable: (entry) => users.length > 0 }"
                  deleteIcon="times"
                  hideSearch hideRefresh>

                  <template v-slot:cell(active)="row">
                    <span class="badge badge-pill badge-success" v-if="row.item.active">Online</span>
                    <span class="badge badge-pill badge-secondary" v-else>Offline</span>
                  </template>

                </ResourceList>

                <b-alert v-if="userItems.length === 0" show variant="warning">
                  <font-awesome-icon :icon="['fas', 'exclamation']"/>
                  No user selected. You must select some user(s) for them to have access to this booking.
                </b-alert>

              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block href="#" class="text-left" v-b-toggle.accordion-dates variant="light">
                <h4 :class="{colordanger: step3Error}">
                  <font-awesome-icon :icon="['fas', 'calendar']"/>
                  Dates
                </h4>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-dates" accordion="options-accordion" role="tabpanel">
              <b-card-body>

                <b-form-group>
                  <label><strong>Range dates: </strong></label>
                  <DatePicker
                    is-range
                    ref='datepicker'
                    v-model="rangeDates"
                    :disabled-dates="disabledDates"
                    :input-props='rangeInputProps'
                    is-inline is-expanded
                    :columns="$screens({ default: 1, lg: 2 })"/>

                </b-form-group>

                <b-form-group>
                  <b-row>
                    <b-col>
                      <label><strong>Starting time: </strong></label>
                      <b-form-timepicker v-model="startTime" locale="en"></b-form-timepicker>
                    </b-col>
                    <b-col>
                      <label><strong>Ending time: </strong></label>
                      <b-form-timepicker v-model="endTime" locale="en"></b-form-timepicker>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-card-text class="small text-muted">Booking length : {{ bookingLength }}</b-card-text>

              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-form-group>

      <div class="text-center"
           style="padding-top:10px; padding-bottom:10px;"
           v-if="loading">
        <b-spinner class="align-middle"></b-spinner>
        <strong style="margin-left:10px;">Loading...</strong>
      </div>
      <div style="float: right;" v-else>
        <b-button
          v-if="edit && hasBookingWrite && !(booking.status==='running' || booking.status==='scheduled')"
          @click.stop="$eventBus.$emit('delete-booking-show', booking.identifier)"
          form="bookingForm"
          variant="danger"
          style="margin-left:10px;">
          <font-awesome-icon :icon="['fas', 'calendar-times']"/>
          Delete
        </b-button>

        <b-button
          v-if="edit && hasBookingWrite && booking.approved && (booking.status==='running' || booking.status==='scheduled')"
          @click.stop="$eventBus.$emit('cancel-booking-show', booking.identifier)"
          form="bookingForm"
          variant="warning"
          style="margin-left:10px;">
          <font-awesome-icon :icon="['fas', 'ban']"/>
          Cancel
        </b-button>

        <b-button
          v-if="booking == null || !booking.approved"
          form="bookingForm"
          @click.stop="onAddEditBooking(false)"
          variant="primary"
          style="margin-left:10px;">
          <font-awesome-icon :icon="['fas', 'calendar-check']"/>
          {{ edit ? 'Update' : 'Create' }}
        </b-button>

        <b-button v-if="hasBookingWrite  && (booking == null || !booking.approved)"
                  variant="success"
                  @click.stop="onAddEditBooking(true)"
                  style="margin-left:10px;">
          <font-awesome-icon :icon="['fas', 'check']"/>
          {{ edit ? 'Update and approve' : 'Create and approve' }}
        </b-button>

      </div>
    </form>
  </b-modal>
</template>

<script>
import store from "../../store";
import moment from 'moment';
import TypeAhead from '../form/TypeAhead'
import DatePicker from 'v-calendar'
import {status_to_variant} from '@/common'
import {print_duration} from '@/filters'
import WorkflowList from '../workflows/WorkflowList'
import InputText from '../form/InputText'

export default {
  name: "BookingListForm",
  components: {
    DatePicker,
    TypeAhead,
    WorkflowList,
    InputText
  },
  created() {
    this.$eventBus.$off("remove-user")
    this.$eventBus.$on("remove-user", (identifier) => {
      let index = this.userItems.findIndex(element => identifier === element.identifier)
      this.userItems.splice(index, 1)
    })

    this.$eventBus.$off("remove-workzone")
    this.$eventBus.$on("remove-workzone", (identifier) => {
      let index = this.workzonesSelected.findIndex(element => identifier === element.identifier)
      this.workzonesSelected.splice(index, 1)
      this.updateAvailability()
    })

    this.$eventBus.$off("add-booking-show")
    this.$eventBus.$on("add-booking-show", (groupIdentifier, start) => {
      this.reset("Create a booking", groupIdentifier)

      // Handle case in which user cannot see other users
      if (this.users.length === 0) {
        store.dispatch('users/loadProfile').then(() => {
          this.userItems.push(store.getters["users/getProfile"]())
        })
      }
      let end = moment(start).add(moment.duration(1, 'hour'))
      this.rangeDates = {
        start: new Date(start),
        end: new Date(moment(start).add(moment.duration(1, 'hour')))
      }

      this.startTime = moment(start).format('HH:mm')
      this.endTime = moment(end).format('HH:mm')
      this.name = ""
      this.edit = false
      this.groupIdentifier = groupIdentifier
      this.updateAvailability()
      this.$bvModal.show("bookingAddFormModal")
    })

    this.$eventBus.$off("edit-booking-show")
    this.$eventBus.$on("edit-booking-show", (identifier) => {
      let booking = this.$store.getters["bookings/get"](identifier)
      Promise.all([
        this.$store.dispatch("users/loadGroup", {groupIdentifier: booking.group_identifier}),
        this.$store.dispatch("workzones/loadGroup", booking.group_identifier)
      ]).then(() => {
        this.reset(booking.approved ? "Booking details" : "Edit a booking", booking.group_identifier)
        this.identifier = booking.identifier
        this.booking = booking

        this.booking.specs.users.forEach(element => {
          let user = this.$store.getters["users/get"](element.identifier)
          this.userItems.push(user)
        })

        if (this.booking.specs.workzones_amount !== undefined)
          this.workzoneSelection = "automatic"
        else
          this.workzoneSelection = "select"

        this.booking.specs.workzones.forEach(element => {
          let workzone = this.$store.getters["workzones/get"](element.identifier)
          this.workzonesSelected.push(workzone)
        })

        this.rangeDates = {
          'start': new Date(this.booking.start),
          'end': new Date(this.booking.end)
        }
        this.startTime = moment(this.booking.start).format('HH:mm')
        this.endTime = moment(this.booking.end).format('HH:mm')

        this.name = this.booking.name

        this.edit = true
        this.groupIdentifier = booking.group_identifier
        this.updateAvailability()
        this.$bvModal.show("bookingAddFormModal")
      })
    })

    this.$eventBus.$off("cancel-booking-show")
    this.$eventBus.$on("cancel-booking-show", (identifier) => {
      let booking = this.$store.getters["bookings/get"](identifier)
      this.$deleteModal({
        title: "Cancel booking",
        text: "Are you sure to cancel " + booking.name + " ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('bookings/cancel', {
            identifier: booking.identifier,
            group_identifier: booking.group_identifier
          }).then(() => {
            this.$bvModal.hide("bookingAddFormModal")
            this.$eventBus.$emit('success', 'The booking will be cancelled')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      })
    })

    this.$eventBus.$off("delete-booking-show")
    this.$eventBus.$on("delete-booking-show", (identifier) => {
      let booking = this.$store.getters["bookings/get"](identifier)
      this.$deleteModal({
        title: "Delete booking",
        text: "Are you sure to delete " + booking.name + " ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('bookings/delete', {
            identifier: booking.identifier,
            group_identifier: booking.group_identifier
          }).then(() => {
            this.$bvModal.hide("bookingAddFormModal")
            this.$eventBus.$emit('success', 'The booking has been deleted')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      })
    })

    this.$eventBus.$off("prune-booking-show")
    this.$eventBus.$on("prune-booking-show", (groupIdentifier) => {
      this.$deleteModal({
        title: "Prune bookings",
        text: "All bookings finished or in a completed state with their associated workflows will be deleted."
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('bookings/prune', groupIdentifier).then(() => {
            this.$eventBus.$emit('success', 'Bookings will be pruned')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      })
    })
  },
  data() {
    return {
      workflowFields: store.getters['workflows/getFields']().filter(
        field => (field.key !== "group_identifier"
          && field.key !== "workzone_identifier")
      ),

      booking: null,
      groupIdentifier: null,

      edit: false,
      loading: false,

      title: "",
      identifier: "",
      users: [],

      workzonesSelection: "automatic",

      // date time selection
      rangeDates: {},
      startTime: '',
      endTime: '',
      rangeInputProps: {
        placeholder: "Select range dates",
        readonly: true
      },
      step1Error: false,

      // user selection
      userFields: ['identifier', 'name', 'remove_user'],
      userItems: [],
      step2Error: false,

      // workzone selection
      workzones: [],
      workzoneFields: ['identifier', 'remove_workzone'],
      workzonesSelected: [],
      workzoneSelection: 0,
      workzonesAmount: 1,
      step3Error: false,

      // booking name
      name: "",

      disabledDates: []
    };
  },
  computed: {
    booking_variant() {
      if (!this.booking.approved)
        return "warning"
      else
        return status_to_variant(this.booking.status)
    },
    startDateTime() {
      return moment(moment(this.rangeDates['start']).format('YYYY-MM-DD') + 'T' + this.startTime).local().format()
    },
    hasBookingWrite() {
      return this.$store.getters["token/hasPermission"]("BOOKING", "WRITE")
    },
    endDateTime() {
      return moment(moment(this.rangeDates['end']).format('YYYY-MM-DD') + 'T' + this.endTime).local().format()
    },
    bookingLength() {
      if (this.startDateTime && this.endDateTime) {
        let start = moment(this.startDateTime)
        let end = moment(this.endDateTime)

        if (start > end)
          return "Invalid (dates must be chronological)"

        return print_duration(moment.duration(end.diff(start)))
      }
      return "Invalid"
    }
  },
  methods: {
    reset(title, group_identifier) {
      this.booking = null
      this.title = title
      this.edit = false

      this.users = store.getters['users/getGroup'](group_identifier)
      this.workzones = store.getters['workzones/getGroup'](group_identifier)

      this.userItems = []
      this.workzonesSelected = []
      this.workzonesAmount = 1

      this.step1Error = false
      this.step2Error = false
      this.step3Error = false

      this.rangeDates = {}
      this.startTime = null
      this.endTime = null

      this.name = ""

      this.color = 'dodgerblue'
      this.minDate = new Date().toISOString()
      this.workzonesSelection = "automatic"
      this.groupIdentifier = group_identifier
    },
    onAddEditBooking(approve) {
      let data = {
        start: this.startDateTime,
        end: this.endDateTime,
        specs: {
          users: []
        },
        name: this.name
      }

      if (this.name === "") {
        this.$eventBus.$emit("error", "Booking name is required.")
        return
      }


      this.step1Error = false
      this.step2Error = false
      this.step3Error = false

      if (this.workzonesSelection === "automatic")
        data.specs['workzones_amount'] = this.workzonesAmount
      else {
        if (this.workzonesSelected.length > 0) {
          data.specs["workzones"] = []
          for (let workzone of this.workzonesSelected) {
            data.specs["workzones"].push({
              "identifier": workzone.identifier
            })
          }
        } else {
          this.$eventBus.$emit("error", "If you choose to specify workzones, you must choose at least one")
          this.$root.$emit('bv::toggle::collapse', 'accordion-workzones')
          this.step1Error = true
          return
        }
      }

      if (this.userItems.length === 0) {
        this.$eventBus.$emit("error", "At least one user access must be specified")
        this.$root.$emit('bv::toggle::collapse', 'accordion-users')
        this.step2Error = true
        return
      }

      for (let user of this.userItems) {
        data.specs["users"].push({
          "identifier": user.identifier
        })
      }

      let now = moment()
      if (data.start < now || data.end < data.start) {
        this.$eventBus.$emit("error", "Date range is invalid.")
        this.$root.$emit('bv::toggle::collapse', 'accordion-dates')
        this.step3Error = true
        return
      }

      this.loading = true
      if (this.edit) {
        store.dispatch("bookings/update", {
          identifier: this.identifier,
          group_identifier: this.groupIdentifier,
          data,
          approve
        }).then(() => {
          this.loading = false
          this.$bvModal.hide("bookingAddFormModal")
          if (approve)
            this.$eventBus.$emit("success", "The booking has been approved")
          else
            this.$eventBus.$emit("success", "The booking has been updated")
        }).catch(response => {
          this.loading = false
          this.$eventBus.$emit("http-error", response)
        });
      } else {
        store.dispatch("bookings/add", {group_identifier: this.groupIdentifier, data, approve}).then((response) => {
          this.loading = false
          this.$bvModal.hide("bookingAddFormModal")
          if (approve)
            this.$eventBus.$emit("success", "The booking has been approved")
          else
            this.$eventBus.$emit("success", "The booking has been submitted and pending for approval")
        }).catch(response => {
          this.loading = false
          this.$eventBus.$emit("http-error", response)
        });
      }
    },
    addWorkzone(workzone_identifier) {
      if (workzone_identifier === null)
        return
      if (this.workzonesSelected.findIndex(element => element.identifier === workzone_identifier) !== -1) {
        this.$eventBus.$emit('info', 'This workzone has already been added.')
        return
      }
      let workzone = store.getters["workzones/get"](workzone_identifier)
      this.workzonesSelected.push(workzone)
      this.$refs.workzoneTypeahead.clear()
      this.updateAvailability()
    },
    addAllWorkzones() {
      this.workzonesSelected.splice(0, this.workzonesSelected.length)
      this.workzones.forEach((workzone) => {
        this.workzonesSelected.push(workzone)
      })
      this.updateAvailability()
    },
    removeAllWorkzones() {
      this.workzonesSelected.splice(0, this.workzonesSelected.length)
      this.updateAvailability()
    },
    addUser(user_identifier) {
      if (!user_identifier)
        return

      let index = this.userItems.findIndex(element => user_identifier === element.identifier)
      if (index !== -1) {
        this.$eventBus.$emit('info', 'This user has already been added.')
        return
      }

      let user = this.$store.getters["users/get"](user_identifier)
      this.userItems.push(user)
      this.$refs.userTypeahead.clear()
    },
    addAllUsers() {
      this.removeAllUsers()
      this.users.forEach((user) => {
        this.userItems.push(user)
      })
    },
    removeAllUsers() {
      this.userItems.splice(0, this.userItems.length)
    },
    updateAvailability() {
      let data = {}
      if (this.workzonesSelected.length > 0) {
        data['workzones'] = []
        for (let workzone of this.workzonesSelected)
          data['workzones'].push({"identifier": workzone.identifier})
      } else
        data['workzones_amount'] = parseInt(this.workzonesAmount)

      this.$store.dispatch("bookings/loadAvailability", {groupIdentifier: this.groupIdentifier, data}).then(() => {
        this.disabledDates.splice(0, this.disabledDates.length)

        // Disable before now
        this.disabledDates.push({
          start: moment(0).format('YYYY-MM-DD'),
          end: moment().subtract(1, 'days').format('YYYY-MM-DD')
        })
        this.$store.getters["bookings/getAvailability"]().forEach(element => {

          let start = moment(element['start'])
          let end = moment(element['end'])

          if (start !== start.startOf('day'))
            start = start.add(1, 'days')

          if (end.diff(start, 'days') === 1) {
            this.disabledDates.push(start.format('YYYY-MM-DD'))
          }
          if (end.diff(start, 'days') > 1) {
            this.disabledDates.push({
              start: start.format('YYYY-MM-DD'),
              end: end.subtract(1, 'days').format('YYYY-MM-DD')
            })
          }
        })
      })
    }
  }
};
</script>

<style>
.modal-open .modal.modal-allow-overflow {
  overflow: visible;
}

.colordanger {
  color: indianred
}
</style>
