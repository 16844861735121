import Vue from 'vue'
import Config from '../../Config'
import {EventSourcePolyfill} from 'event-source-polyfill';

const eventBus = new Vue({
  data() {
    return {
      websocket_event_source: {}
    }
  },
  methods: {
    stream() {
      if (this.event_source === undefined) {
        this.event_source = new EventSourcePolyfill(Config.API_STREAM_URL, {
          params: {"level": 'info'}
        });

        this.event_source.addEventListener('message', event => {
          this.$emit("event", JSON.parse(event.data))
        }, false);
      }
    },
    closeStream() {
      if (this.event_source !== undefined) {
        let event_source = this.event_source
        this.event_source = undefined
        event_source.close()
      }
    },
    streamWorkflow(identifier) {
      if (this.websocket_event_source[identifier] === undefined) {
        this.websocket_event_source[identifier] = new EventSourcePolyfill(Config.API_STREAM_URL, {
          params: {"level": 'trace', 'load': 'meta'}
        });

        this.websocket_event_source[identifier].addEventListener('message', event => {
          let data = JSON.parse(event.data)
          let identifier = data.workflow
          this.$emit("workflow-event-" + identifier, data)
          if (data.workflow_end) {
            this.websocket_event_source[identifier].close()
          }

        }, false);
      }
    },
    closeStreamWorkflow(identifier) {
      if (this.websocket_event_source[identifier] !== undefined) {
        let event_source = this.websocket_event_source[identifier]
        this.websocket_event_source[identifier] = undefined
        event_source.close()
      }

    }
  }
});

export default eventBus;
