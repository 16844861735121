<template>
  <WorkflowList
    :searchFilters="{group_identifier: groupIdentifier}"
    :fields="fields"
    :busy="busy">
  </WorkflowList>
</template>

<script>
import store from '../../store'
import WorkflowList from '../workflows/WorkflowList.vue'

export default {
  name: "WorkflowTab",
  components: {
    WorkflowList
  },
  props: {
    groupIdentifier: String,
  },
  data() {
    return {
      busy: false,
      fields: store.getters['workflows/getFields']().filter(
        field => (field.key !== "group_identifier"
          && field.key !== "workzone_identifier")
      )
    }
  }
}
</script>
