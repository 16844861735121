<template>
  <div id="workflow-view">
    <div id="workflow-sidebar">
      <div class="filterpanel">
        <div class="filterSection">
          <div class="filterSectionHeader">
            <font-awesome-icon :icon="['fa', 'calendar']"/>
            TIME
          </div>

          <b-form-radio-group stacked @change="time=$event" v-model="time">
            <b-form-radio name="scope" value="stream">
              Real time
            </b-form-radio>
            <b-form-radio name="scope" value="pick">
              Select range
            </b-form-radio>
          </b-form-radio-group>

          <DatePicker
            mode='range'
            v-model="rangeDates"
            :max-date="new Date()"
            @dayclick="time='pick'"
            is-inline
            is-range/>

          <b-form-group style="padding-top:10px">
            <b-row>
              <b-col>
                <label><strong>Start time: {{ moment(rangeDates.start).format("DD/MM") }}</strong></label>
                <b-form-timepicker v-model="startTime" locale="en" @input="time='pick'"/>
              </b-col>
              <b-col>
                <label><strong>End time: {{ moment(rangeDates.end).format("DD/MM") }}</strong></label>
                <b-form-timepicker v-model="endTime" locale="en" @input="time='pick'"/>
              </b-col>
            </b-row>
          </b-form-group>

        </div>

        <div class="filterSection">
          <div class="filterSectionHeader">
            <font-awesome-icon :icon="['fa', 'cubes']"/>
            SCOPE
          </div>
          <b-form-group>
            <b-form-radio-group stacked @change="scope=$event" v-model="scope">
              <b-form-radio name="scope" value="all">
                All
              </b-form-radio>
              <b-form-radio name="scope" value="group">
                <TypeAhead ref="groupSelector"
                           resourceType="group"
                           v-model="group"
                           @hit="groupIdentifier=$event"/>
              </b-form-radio>
              <b-form-radio name="scope" value="workzone">
                <TypeAhead ref="workzoneSelector"
                           resourceType="workzone"
                           v-model="workzone"
                           @hit="workzoneIdentifier=$event"/>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

        </div>
        <div class="filterSection">
          <div class="filterSectionHeader">
            <font-awesome-icon :icon="['fa', 'user']"/>
            USER
          </div>
          <b-form-group>
            <b-form-radio-group stacked @change="userfilter=$event" v-model="userfilter">
              <b-form-radio name="userfilter" value="all" default>
                All
              </b-form-radio>
              <b-form-radio name="userfilter" value="typeahead">
                <TypeAhead ref="userSelector"
                           resourceType="user"
                           v-model="user"
                           @hit="userIdentifier=$event"/>
              </b-form-radio>
              <b-form-radio name="userfilter" value="string">
                <input type="text"
                       v-model="username"
                       placeholder="Type Username"
                       class="form-control"/>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>


        <b-button variant="primary" @click="applyFilter" style="margin:20px">
          Apply Filter
        </b-button>
      </div>
    </div>

    <div id="workflow-panel">
      <div id="eventlist">
        <WorkflowList
          v-if="tabValue === 0"
          ref="WorkflowList"
          :searchFilters="searchFilters"
          :busy="busy">

          <template v-slot:cell(group_identifier)="row">
            <a v-if="row.item.group_identifier != null"
               class="filterlink"
               @click="scope='group'; groupIdentifier=row.item.group_identifier; applyFilter()">
              {{ print_group(row.item.group_identifier) }}
            </a>
          </template>

          <template v-slot:cell(workzone_identifier)="row">
            <div :v-if="row.item.workzone_identifier != null" class="link">
              <a @click="scope='workzone'; workzoneIdentifier=row.item.workzone_identifier; applyFilter()"
                 class="filterlink">
                {{ print_workzone(row.item.workzone_identifier) }}
              </a>
              <span class="badge">
								{{ row.item.workzone_identifier }}
							</span>
            </div>
          </template>

          <template v-slot:cell(username)="row">
            <a @click="userfilter='string'; username=row.item.username; applyFilter()" class="filterlink">
              {{ row.item.username }}
            </a>
          </template>
        </WorkflowList>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store'
import TypeAhead from '../../components/form/TypeAhead.vue'
import WorkflowList from './WorkflowList.vue'
import eventBus from '../../store/modules/eventBus'
import DatePicker from 'v-calendar'
import moment from 'moment'

export default {
  name: "WorkflowView",
  components: {
    TypeAhead, WorkflowList, DatePicker
  },
  data() {
    var start = new Date(moment().format())
    var end = new Date(moment().subtract(2, 'day').format())
    return {
      workzone: "",
      workzoneIdentifier: null,
      group: "",
      groupIdentifier: null,
      user: "",
      userIdentifier: null,
      username: "",
      groupSelected: false,
      workzoneSelected: false,
      scope: "all",
      userfilter: "all",
      time: "stream",
      tabValue: 0,
      busy: false,
      rangeDates: {
        start: start,
        end: end
      },
      startTime: moment(start).set('minutes', 0).format('HH:mm'),
      endTime: moment(end).format('HH:mm'),
      searchFilters: {}
    }
  },
  beforeDestroy: function () {
    eventBus.closeStream()
  },
  mounted() {
    this.busy = true
    Promise.all([
      store.dispatch("groups/loadAll", {}),
      store.dispatch("workzones/loadAll"),
      store.dispatch("users/loadAll", {})
    ]).then(() => {
      this.busy = false
    })
  },
  computed: {
    startDateTime() {
      return moment(moment(this.rangeDates.start).format('YYYY-MM-DD') + 'T' + this.startTime).local().format()
    },
    endDateTime() {
      return moment(moment(this.rangeDates.end).format('YYYY-MM-DD') + 'T' + this.endTime).local().format()
    },
  },
  methods: {
    moment(value) {
      return moment(value)
    },
    print_workzone: function (workzone_identifier) {
      let workzone = this.$store.getters['workzones/get'](workzone_identifier)
      if (!workzone) return ''
      return workzone.name
    },
    print_group: function (group_identifier) {
      let group = this.$store.getters['groups/get'](group_identifier)
      if (!group) return ''
      return group.name
    },
    print_user: function (user_identifier) {
      let group = this.$store.getters['users/get'](user_identifier)
      if (!group) return ''
      return group.name
    },
    resource() {
      if (this.tabValue === 0)
        return "workflows"
      else
        return "events"
    },
    applyFilter() {
      this.busy = true

      // Clear all filters
      delete this.searchFilters.group_identifier
      delete this.searchFilters.workzone_identifier
      delete this.searchFilters.start_time
      delete this.searchFilters.end_time
      delete this.searchFilters.user

      // Update the fields
      if (this.groupIdentifier !== null)
        this.$refs.groupSelector.refresh(this.$store.getters['groups/get']([this.groupIdentifier]).name)

      if (this.workzoneIdentifier !== null)
        this.$refs.workzoneSelector.refresh(this.workzoneIdentifier)

      if (this.time !== "stream") {
        this.searchFilters.start_time = this.startDateTime
        this.searchFilters.end_time = this.endDateTime
      }

      // Scope
      if (this.scope === "workzone")
        this.searchFilters.workzone_identifier = this.workzoneIdentifier

      else if (this.scope === "group")
        this.searchFilters.group_identifier = this.groupIdentifier

      // User
      if (this.userfilter === "typeahead")
        this.searchFilters.user = this.print_user(this.userIdentifier)

      else if (this.userfilter === "string" && this.username !== "")
        this.searchFilters.user = this.username

      this.$refs["WorkflowList"].refresh().then(() => {
        this.busy = false
      })
    }
  }
}
</script>

<style>
.custom-control {
  z-index: auto !important;
}

.filterlink {
  color: var(--lade-navy) !important;
}

.filterlink:hover {
  color: var(--lade-color-black) !important;
}

.filterSection {
  padding: 20px;
}

.filterSection:hover {
  background-color: var(--lade-color-white);
}

.filterSectionHeader {
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--lade-color-grey);
}

/* The sidebar menu */
#workflow-sidebar {
  display: flex;
  height: 100%;
  width: 300px;
}
#workflow-panel {
  display: flex;
  width: calc(100vw - 300px);
}
#workflow-view{
  display: flex;
  background-color: #ffffff;
}

</style>
