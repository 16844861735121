import Vue from 'vue'
import Config from '../../Config'
import {setDictValue, build_resource_list_params} from '@/common'
import {field_actions} from './fields'
import {print_date} from '@/filters'
import eventBus from './eventBus'

const state = {
  list: [],
  _lookup: {},
  eventsource: null,
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  fields: [
    {
      key: 'status',
      label: 'Status',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Type',
      sortable: true
    },
    {
      key: 'username',
      label: 'User',
      sortable: true
    },
    {
      key: 'start_time',
      label: 'Start',
      sortable: true,
      formatter: print_date,
    },
    {
      key: 'complete_time',
      label: 'End',
      sortable: true,
      formatter: print_date,
    },
    {
      key: "group_identifier",
      label: 'Group',
      sortable: true
    },
    {
      key: "workzone_identifier",
      label: 'Workzone',
      sortable: true
    },
    {
      key: 'description',
      label: 'Description',
      sortable: true
    },
    field_actions
  ],
  filters: {
    isEditable: (entry) => false,
    isExportable: (entry) => false,
    isDeletable: (entry) => false,
    isDownloadable: (entry) => false
  }
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getAll: (state) => () =>
    state.list,
  getFields: (state) => () =>
    state.fields,
  getFilters: (state) => () =>
    state.filters,
  getEvents: (state) => (identifier) =>
    state._workflow_events[identifier],
  getPagination: (state) => () =>
    state.pagination
}


const actions = {
  toggle({dispatch, commit}, item) {
    commit('setDetails', {item, details: !item._showDetails})
  },
  cancel({dispatch, commit}, workflow) {
    var url = ""
    if (workflow.workzone_identifier != null)
      url = Config.API_WORKZONES_URL + "/" + workflow.workzone_identifier + "/workflows/" + workflow.identifier + "/control"
    else if (workflow.group_identifier != null)
      url = Config.API_GROUPS_URL + "/" + workflow.group_identifier + "/workflows/" + workflow.identifier + "/control"
    else
      url = Config.API_WORKFLOWS_URL + "/" + workflow.identifier
    return Vue.http.put(url, {"action": "cancel"})

  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_WORKFLOWS_URL + "/" + identifier).then((response) => {
      let workflow = response.data
      commit('set', {identifier, workflow})
    })
  },
  loadAll({dispatch, commit}, {filters = {}, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)
    var url = ""

    if (filters.workzone_identifier != null)
      url = Config.API_WORKZONES_URL + "/" + filters.workzone_identifier + "/workflows"
    else if (filters.group_identifier != null)
      url = Config.API_GROUPS_URL + "/" + filters.group_identifier + "/workflows"
    else
      url = Config.API_WORKFLOWS_URL

    commit('setFilters', {filters})

    delete filters.workzone_identifier
    delete filters.group_identifier

    return Vue.http.get(url, {params: {...params, ...filters}}).then((response) => {
      let workflows = response.data
      commit('setAll', {workflows})

      eventBus.$off("event")

      // Update workflows only if real_tume and first page
      if (!filters.end_time && state.pagination.page === 1) {

        eventBus.$on("event", function (data) {

          // Load only on begin or end of the workflow
          if (state._lookup[data.workflow] !== undefined && !data.workflow_end) return

          // Apply currently selected filters
          if (state.filters.workzone_identifier !== undefined && state.filters.workzone_identifier !== data.workzone) return
          if (state.filters.group_identifier !== undefined && state.filters.group_identifier !== data.group) return
          if (state.filters.user !== undefined && state.filters.user !== data.user) return

          // Load new workflows and update status
          dispatch('load', data.workflow)

        })
        eventBus.stream()
      } else {
        eventBus.closeStream()
      }
    })
  }
}

const mutations = {
  setLoad(state, value) {
    state.canLoad = value
  },
  setDetails(state, {item, details}) {
    Vue.set(item, '_showDetails', details)
  },
  setEvents(state, {identifier, events, append = false}) {
    if (!append) {
      if (state._workflow_events[identifier] === undefined)
        state._workflow_events[identifier] = []
      else
        state._workflow_events[identifier].splice(0, state._workflow_events[identifier])
    }

    for (let event of events)
      state._workflow_events[identifier].push(event)
  },
  setAll(state, {workflows}) {
    state._lookup = {}
    state.pagination.page = workflows.page
    state.pagination.total_pages = workflows.total_pages
    state.pagination.total = workflows.total
    state.list.splice(0, state.list.length)

    for (let workflow of workflows.data) {
      state.list.push(workflow)
      setDictValue(state._lookup, workflow.identifier, workflow)
    }
  },
  set(state, {identifier, workflow}) {
    if (state._lookup[identifier] === undefined) {
      state.list.unshift(workflow)
      state.pagination.total++
    } else {
      workflow.progress = state._lookup[identifier].progress
    }
    setDictValue(state._lookup, identifier, workflow)
  },
  setStatus(state, {identifier, status}) {
    state._lookup[identifier].status = status
  },
  setFilters(state, filters) {
    state.filters = filters
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
