<template xmlns="http://www.w3.org/1999/html">
  <PanelView>
    <template v-slot:sidebar>
      <GroupListForm
        addEvent="add-group-show"
        editEvent="edit-group-show"
        deleteEvent="delete-group-show"
      ></GroupListForm>
      <b-card no-body header="Groups" style="height:100%;">
        <template v-slot:header>
          <div class="container" style="padding: 0;">
            <!-- title -->
            <div class=row style="display: flex; justify-content: space-evenly;">
              <div style="margin: 0.375rem 0; white-space: nowrap; font-size: 1.2em">
                Groups
              </div>
            </div>
            <!-- search -->
            <div class=row>
              <div class="col" style="margin: 0.350rem auto;">
                <input type="text" v-model="search" placeholder="Search" class="search" @keyup="filter"
                       style=" margin-top: 0; padding: 2px; height:20px; width: 100%"/>

              </div>
            </div>
            <!-- pagination -->
            <div class="row">
              <div class="pagination">
                <b-button :class="[page > 1 ? 'page-chevron-active' : 'page-chevron-deactivated', 'page-chevron']"
                          @click="previous_page()">
                  <font-awesome-icon :icon="['fas', 'chevron-left']"/>
                </b-button>
                <span class="pagination-text">
                    <b-button class="pagination-button">
                    {{ skip + 1 }} - {{ Math.min(skip + limit, total) }} on {{ total }}
                    </b-button>
                    <div class="page-options">
                      <div style="text-align: center; color: #5f6368; font-weight: 500; user-select: none; ">
                        Order By
                      </div>
                      <div style="display: flex; justify-content: center; flex-wrap: wrap">
                        <b-button v-for="item in sortOptions"
                                  :key="item.text"
                                  @click.up="updateSort(item)"
                                  :class="[isActiveSortOption(item) ? 'page-option-active': '', 'page-option']">
                          {{ item.text }}
                        </b-button>
                      </div>
                      <div style="text-align: center; color: #5f6368; font-weight: 500; user-select: none; ">
                        Display up to
                      </div>
                      <div style="display: flex; justify-content: center; flex-wrap: wrap">
                        <b-button v-for="lim in limitOptions"
                                  :key="lim"
                                  @click.up="updateLimit(lim)"
                                  :class="[lim === limit? 'limit-selected': '', 'page-option']">
                          {{ lim }}
                        </b-button>
                      </div>
                    </div>
                </span>
                <b-button :class="[page < total_pages ? 'page-chevron-active' : 'page-chevron-deactivated', 'page-chevron']"
                          @click="next_page()">
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </b-button>

                <b-button class="limit-button"
                          @click="search='' ; page=1 ; get_groups()">
                  <font-awesome-icon :icon="['fas', 'sync']"/>
                </b-button>
                <b-button class="limit-button" v-if="canAddGroup()"
                          @click="$eventBus.$emit('add-group-show')">
                  <font-awesome-icon :icon="['fas', 'plus']"/>
                </b-button>

              </div>

            </div>

          </div>
        </template>
        <b-list-group flush class="grouplist">
          <b-list-group-item button @click="selectGroup('all')" :active="currentGroupIdentifier==='all'">
            All groups
          </b-list-group-item>
          <b-list-group-item button
                             v-for="group in groups" :key="group.identifier" @click="selectGroup(group.identifier)"
                             :active="currentGroup && currentGroupIdentifier === group.identifier">
            <Icon :icon="group.icon" :square=false
                  style="width:48px !important; height:48px !important; float:left; margin-right:10px;
                  object-fit: scale-down;"/>
            {{ group.name }} <br/>
            <small>{{ group.description }}</small>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </template>

    <template v-slot:panel>
      <div v-if="showPanel" style="background-color: var(--lade-color-white-hover)">
        <div v-if="currentGroupIdentifier === 'all'" class="bundle-title">
          <span style="font-size:1.4em;">All groups</span>
        </div>
        <div v-else style="padding-bottom: 20px">
          <Icon :icon="currentGroup.icon" :square=false style="float:left; margin:20px;"/>

          <div class="bundle-title">
            <span style="font-size:1.4em;">Group {{ currentGroup.name }} </span>
            <font-awesome-icon :icon="['fas', 'pencil-alt']"
                               @click.stop="$eventBus.$emit('edit-group-show', currentGroup.identifier)"
                               style="margin-left:5px; cursor: pointer; height:20px;" v-if="isEditable(currentGroup)"/>
            <font-awesome-icon :icon="['fas', 'trash']"
                               @click.stop="deleteGroup()" v-if="isDeletable(currentGroup)"
                               style="margin-left:10px; cursor: pointer; height:20px; color:indianred;"/>
          </div>
          <div class="group-description">
            <span> {{ currentGroup.description }}</span>
            <br v-if="currentGroup.description!=='' && currentGroup.description!== null " />
            <span style="font-style:italic;"> Creation date: {{ currentGroup.created_at | print_date }} </span>
          </div>
          <div style="padding-bottom: 5px; padding-left: 30px;">
            <VisibilityBadge :visibility="currentGroup.visibility" style="margin-left: 4px; margin-right: 2px"/>
            <span style="font-weight: bold"> Visibility: </span>{{currentGroup.visibility}}
          </div>
          <div style="padding-bottom: 5px; padding-left: 30px;" >
            <font-awesome-icon class='fa-icon' :icon="['fas', 'sitemap']" alt="Workzones" style="margin-right: 2px"/>
            <span style="font-weight: bold">
            Workzones:
            </span>
            <span v-if="currentGroup.workzones.length === 0" style="font-weight: 700;">
                ∅
            </span>
            <b-list-group flush v-else>
              <b-list-group-item v-for="workzone of currentGroup.workzones"
                                 :key="workzone.identifier">
                {{workzone.name}} <small>{{workzone.identifier}}</small>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div style="padding-bottom: 5px; padding-left: 30px; display: flex; align-items: center" >
            <font-awesome-icon class='fa-icon' :icon="['fas', 'database']" alt="Size" style="margin-right: 10px"/>
            <span style="margin-right: 2px; font-weight: bold">
            Size:
            </span>
            <span v-if="currentGroup.content_size > 0">
              {{ currentGroup.content_size | print_human_readable_size }}
            </span>
            <span v-else-if="currentGroup.limit_content_size === 0">
              Group cannot have content. <i>(limited)</i>
            </span>
            <span v-else style="margin-right: 4px">
              0
            </span>
            <span v-if="currentGroup.limit_content_size > 0">/ {{
                currentGroup.limit_content_size | print_human_readable_size
              }}</span>
            <CapacityChart :chart-data="capacitydata" :options="chart_options" class="capacity-chart"
                           v-if="(currentGroup.limit_content_size > 0) && (currentGroup.content_size >= 0)"/>
          </div>

        </div>
        <b-tabs class="viewtabs" ref="grouptabs" v-model="tabValue" @input="setRoute()">
          <b-tab title="Users" :disabled="!hasReadPermission('USER')">
<!--            <ResourceList v-if="currentGroupIdentifier==='all' && tabValue===0"-->
<!--                          resource="users"-->
<!--                          addIcon="user-plus"-->
<!--                          addEvent="add-user-show"-->
<!--                          editEvent="edit-user-show"-->
<!--                          deleteEvent="delete-user-show"-->
<!--                          sortAction="users/sort"-->
<!--                          resourceListForm="UserListForm"-->
<!--                          autoLoad-->
<!--                          paginate>-->

<!--              <template v-slot:cell(active)="row">-->
<!--                <span class="badge badge-pill badge-success" v-if="row.item.active">Online</span>-->
<!--                <span class="badge badge-pill badge-secondary" v-else>Offline</span>-->
<!--              </template>-->


<!--              <template v-slot:customActions="actions">-->
<!--                <font-awesome-icon-->
<!--                  class='fa-icon action-icon'-->
<!--                  v-if="isDisconnectable(actions.row.item) && actions.row.item.active"-->
<!--                  @click.stop="disconnect(actions.row.item.identifier)"-->
<!--                  :icon="['fas', 'sign-out-alt']"-->
<!--                  title="Log out user"/>-->
<!--              </template>-->

<!--            </ResourceList>-->
            <UserTab v-if="currentGroupIdentifier==='all' && tabValue===0"></UserTab>
            <UserTab :groupIdentifier="currentGroupIdentifier" v-else-if="tabValue===0"></UserTab>
          </b-tab>
          <b-tab title="Bundles" :disabled="!hasReadPermission('BUNDLE')">
            <b-alert v-if="currentGroupIdentifier==='all'" variant="info" show style="margin:10px;">
              You need to select a group. <br/>
              You can also manage all bundles
              <router-link :to="{ name: 'bundles'}">here</router-link>
            </b-alert>
            <BundleTab :groupIdentifier="currentGroupIdentifier" v-else-if="tabValue===1"></BundleTab>
          </b-tab>
          <b-tab title="Workflows" :disabled="!hasReadPermission('WORKFLOW')">
            <b-alert v-if="currentGroupIdentifier==='all'" variant="info" show style="margin:10px;">
              You need to select a group. <br/>
              You can also manage all workflows
              <router-link :to="{ name: 'workflow'}">here</router-link>
            </b-alert>
            <WorkflowTab :groupIdentifier="currentGroupIdentifier" v-else-if="tabValue===2"></WorkflowTab>
          </b-tab>
          <b-tab title="Roles" :disabled="!hasReadPermission('ROLE')">
            <b-alert v-if="currentGroupIdentifier==='all'" variant="info" show style="margin:10px;">
              You need to select a group.
            </b-alert>
            <RoleTab :groupIdentifier="currentGroupIdentifier" v-else-if="tabValue===3"></RoleTab>
          </b-tab>
        </b-tabs>
      </div>
    </template>
  </PanelView>
</template>

<script>
import {print_human_readable_size} from '@/filters'
import store from '../../store'
import CapacityChart from './CapacityChart'
import GroupListForm from './GroupListForm'
import UserTab from './UserTab'
import RoleTab from './RoleTab'
import BundleTab from './BundleTab'
import WorkflowTab from './WorkflowTab'
import BookingTab from './BookingTab'
import PanelView from '../PanelView'
import Icon from '../form/Icon'
import VisibilityBadge from '../VisibilityBadge'
export default {
  name: "GroupList",
  components: {
    CapacityChart,
    GroupListForm,
    UserTab,
    RoleTab,
    BundleTab,
    WorkflowTab,
    BookingTab,
    PanelView,
    Icon,
    VisibilityBadge
  },
  data: () => ({
    currentGroupIdentifier: 'all',
    tabValue: 0,
    showPanel: false,
    chart_options: {
      legend: {
        display: false
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return data['labels'][tooltipItem[0]['index']]
          },
          label: function (tooltipItem, data) {
            return print_human_readable_size(data['datasets'][0]['data'][tooltipItem['index']])
          },
        },
        backgroundColor: '#FFF',
        titleFontSize: 16,
        titleFontColor: 'black',
        bodyFontColor: 'black',
        bodyFontSize: 14,
        displayColors: false
      },
    },
    search: "",
    groups: store.getters['groups/getAll'](),
    filters: store.getters['groups/getFilters'](),
    currentGroup: null,
    resourceTabMapping: {
      0: 'users',
      1: 'bundles',
      2: 'workflows',
      3: 'roles',
    },
    skip: store.getters['groups/getSkip'](),
    total: store.getters['groups/getTotal'](),
    page: store.getters['groups/getPage'](),
    total_pages: store.getters['groups/getTotalPages'](),
    limit: 15,
    showLimitOptions: false,
    limitOptions: [10, 15, 25, 50],
    sortOptions: [
      {orderBy: 'created_date', desc: false, text: 'Newest'},
      {orderBy: 'created_date', desc: true, text: 'Oldest'},
      {orderBy: 'name', desc: true, text: 'Alphabetic'},
    ],
    showSortOptions: false,
    orderBy: 'created_date',
    desc: false
  }),
  mounted() {
    this.limit = 15
    this.selectTab(this.$route.params.resource)
    this.get_groups()
  },
  computed: {
    capacitydata() {
      return {
        datasets: [{
          data: [this.currentGroup.content_size,
            Math.max(this.currentGroup.limit_content_size - this.currentGroup.content_size, 0)],
          backgroundColor: ['indianred', 'lightgreen']
        }],
        labels: ['Used', 'Free']
      }
    }
  },
  methods: {
    get_groups() {
      this.$store.dispatch('groups/loadAll', {
        orderBy: this.orderBy, desc: this.desc, labels: [], page: this.page,
        limit: this.limit, search: this.search, load: "meta"
      }).then(() => {
        this.selectGroup(this.$route.params.identifier)
        this.page = this.$store.getters['groups/getPage']()
        this.total_pages = this.$store.getters['groups/getTotalPages']()
        this.limit = this.$store.getters['groups/getLimit']()
        this.skip = store.getters['groups/getSkip']()
        this.total = store.getters['groups/getTotal']()
      })
    },
    next_page() {
      if (this.page < this.total_pages) {
        this.page += 1
        this.get_groups()
      }
    },
    previous_page() {
      if (this.page > 1) {
        this.page -= 1
        this.get_groups()
      }
    },
    isActiveSortOption(data) {
      return data.orderBy === this.orderBy && data.desc === this.desc
    },
    updateLimit(limit) {
      this.showLimitOptions = false
      if (limit !== this.limit) {
        this.limit = limit
        this.get_groups()
      }
    },
    updateSort(data) {
      this.showSortOptions = false
      if (!this.isActiveSortOption(data)) {
        this.orderBy = data.orderBy
        this.desc = data.desc
      }
      this.get_groups()
    },
    hasReadPermission(resource) {
      if (this.currentGroupIdentifier === "all")
        return true

      let permission = this.currentGroup.authz.resources_permissions.find(item => item.resource === resource).permission
      return permission > 0
    },
    canAddGroup(){
      return this.$store.getters["token/hasPermission"]("GROUP", "CREATE")
    },
    editGroup(){
      this.$router.push({
        name: 'editGroup',
        params: {
          identifier: this.currentGroup.identifier
        }
      });
    },
    selectTab(tab) {
      switch (tab) {
        case "users":
          this.tabValue = 0
          break;
        case "bundles":
          this.tabValue = 1
          break;
        case "workflows":
          this.tabValue = 2
          break;
        case "roles":
          this.tabValue = 3
          break;
        default:
          this.tabValue = 0
      }
    },
    setRoute() {
      let current_params = this.$router.history.current.params

      let group_identifier = this.currentGroupIdentifier
      let resource = this.resourceTabMapping[this.tabValue]
      if (current_params.identifier !== group_identifier || resource !== current_params.resource)
        this.$router.push({
          name: 'groups', params: {
            identifier: group_identifier,
            resource: resource
          }
        })
    },
    isEditable(entry) {
      if (entry.authz && (entry.authz.permission & 0x04) === 0) {
        return false;
      }
      return this.filters.isEditable(entry)
    },
    isDeletable(entry) {
      if (entry.authz && (entry.authz.permission & 0x08) === 0) {
        return false;
      }
      return this.filters.isDeletable(entry)
    },
    selectGroup(group_identifier) {
      this.showPanel = false

      if (group_identifier === undefined)
        group_identifier = "all"

      this.currentGroupIdentifier = group_identifier

      // Set URL Parameter
      this.setRoute()

      // Set URL Parameter
      if (this.currentGroupIdentifier !== "all") {
        this.$store.dispatch('groups/load', group_identifier).then(() => {
          this.currentGroup = store.getters['groups/get'](group_identifier)
          this.showPanel = true
        })
      } else {
        this.$nextTick(function () {
          this.showPanel = true
        })

      }

    },
    filter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.page = 1
        this.get_groups()
      }, 600);
    },
    deleteGroup() {
      let group = this.currentGroup
      this.$store.dispatch("bundles/loadGroup", {groupIdentifier:group.identifier, load:"meta"}).then(() => {
        let bundles = store.getters['bundles/getAll']()
        let html = "<br/> <h4> The following bundles will be deleted:</h4> <br/> " +
          "<ul style=\"max-height:300px; overflow-y: auto;\" class=\"list-groups list-groups-flush\">"

        bundles.forEach((bundle)=>
          html +=  "<li style=\"text-align:left;\" class=\"list-groups-item\">" + bundle.tag + "</li>"
        )
        html += "<ul> <br/>"

        let bundlesInThisGroup = bundles.length > 0
        this.$deleteModal({
          title: "Delete groups " + group.name + "?\n",
          html: bundlesInThisGroup?html:"No content will be deleted as there is no bundle in this groups.",
          input: bundlesInThisGroup?'checkbox':undefined,
          width: '50rem',
          inputValue: 0,
          timer: 15000,
          inputValidator: (result) => {
            return !result && 'You need to check the "Confirm" box in order to delete the groups.'
          },
          inputPlaceholder:"I am aware that all the following bundles will be deleted."
        }).then((result) => {
          if (result.value) {
            store.dispatch('groups/delete', group.identifier).then(() => {
              this.selectGroup(undefined)
            }).catch((response) => {
              this.$eventBus.$emit('http-error', response)
            })
          }
        });
      })
    },
    disconnect(userIdentifier) {
      store.dispatch("users/disconnect", {userIdentifier})
    },
    isDisconnectable(entry) {
      if (entry.authz && (entry.authz.permission & 0x04) === 0)
        return false

      return entry.name !== "admin";
    }
  }
}
</script>

<style scoped>
.groupactions {
  float: right
}

.bundle-title {
  padding-top: 10px;
  text-align: center;
}

.group-description {
  text-align: center;
  padding-bottom: 30px;
  overflow-wrap: break-word;
}

.group-limit {
  float: right;
  padding-right: 30px;
}

.capacity-chart {
  width: 25px;
  display: inline-block;
  margin-left: 10px;
}

#group-selection {
  border: None;
  padding-right: 0;
}

.search {
  width: 80%;
}

.tabs {
  float: left;
  width: 100%;
}

.action-icon {
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.pagination-button {
  color: black !important;
  background-color: Transparent !important;
  border: none !important;
  border-radius: 3px;
}

.pagination-button:hover {
  background-color: rgba(28, 22, 22, 0.05) !important;
}

.pagination-button:focus {
  background-color: rgba(28, 22, 22, 0.05) !important;
  border: none !important;
  box-shadow: none ! important;
}

.page-options {
  white-space: normal;
  position: absolute;
  z-index: 101;
  list-style: none;
  overflow-y: auto;
  display: none;
  max-height: 150px;
  border: 0px !important;
  background-color: white !important;
  box-shadow: 0px 2px 4px black;
  border-radius: 3px;
  width: 250px;
}

.page-options button {
  background-color: white !important;
}

.pagination-text:hover .page-options {
  display: block;
}

.page-option {
  border: none !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.page-option:hover {
  background-color: rgba(28, 22, 22, 0.05) !important;
}

.page-option:focus {
  box-shadow: none ! important;
}


select {
  height: 100px;
  line-height: 25px;
  background: #f4f4f4;
}

select:focus {
  outline: none;
}

select[multiple] {
  height: 100px;
  line-height: 25px;
  background: #f4f4f4;
}

.pagination {
  white-space: nowrap;
  display: inline-block;
  margin: 0.350rem auto;
}

.viewtabs {
  background-color: var(--lade-color-white-hover);
}

</style>
